@import 'globals/scss/variables';
@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/extensions';

.transcript {
  margin-bottom: spacing(xl);

  .heading {
    font-size: rem(24);
    font-weight: normal;
    padding-bottom: spacing(md);
  }

  .grid {
    display: grid;
    padding-left: spacing(md);

    @include breakpoint(sm) {
      gap: spacing(md);
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(xl) {
      width: 75%;
    }

    .list {
      list-style: none;
      margin: 0;
      padding: 0;

      .item {
        align-items: flex-start;
        display: flex;
        margin-bottom: spacing(sm);

        .number {
          @include heading-2;
          margin-right: spacing(xs);
        }

        .label {
          @include caption-text;
          margin-top: rem(6);
        }
      }
    }
  }
}
