@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';

.icon {
  @include button-unstyled;
  align-items: center;
  background: $white;
  border-radius: 50%;
  box-shadow: 1px 3px 8px 2px $boxshadow;
  color: $accme-peach;
  cursor: default;
  display: inline-flex;
  height: rem(15);
  justify-content: center;
  width: rem(15);

  svg {
    font-size: rem(19);
  }
}
