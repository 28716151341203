@import '../../../../globals/scss/variables';
@import '../../../../globals/scss/functions';
@import '../../../../globals/scss/mixins';
@import '../../../../globals/scss/extensions';

.container {
  margin-bottom: spacing(md);
  width: 100%;

  @include breakpoint(sm) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(sm);
  }

  .selector {
    align-items: center;
    background-color: $white;
    border: solid rem(0.5) $other-gray;
    border-radius: rem(24);
    box-shadow: inset 0 1px 3px 0 $boxshadow-alt;
    display: flex;
    justify-content: space-between;
    max-width: rem(600);
    padding: rem(2);

    @include breakpoint(md) {
      max-width: rem(600);
    }

    &:focus-within {
      border-color: $accme-blue;
    }

    input {
      height: 0;
      margin: 0;
      opacity: 0;
      width: 0;

      + label {
        @include body-s;
        align-items: center;
        border-radius: rem(24);
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        padding: rem(6);
        position: relative;
        text-align: center;
        transition: all 0.3s ease-in-out;

        @include breakpoint(md) {
          min-width: rem(140);
        }
      }

      &:checked + label {
        background-color: $accme-purple;
        color: $white;
      }
    }

    a {
      @include font-size(14);
      color: $black;
      flex: 1 1 auto;
      padding: rem(6);
      text-align: center;
      text-decoration: none;
    }
  }

  .buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: spacing(sm);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
