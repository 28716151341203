@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.hero {
  background-color: $paler-blue; //matches image in mobile
  background-position: top right; // temp positioning, dependant on client sourced image
  background-repeat: no-repeat;
  background-size: 200%;
  min-height: calc(100vh - #{rem(500)});
  padding: rem(200) spacing(sm) spacing(xxxl);
  width: 100%;

  @include breakpoint(xs) {
    background-size: 100%;
    padding: rem(72) spacing(sm) rem(96);
  }

  @include breakpoint(md) {
    background-position: top right -#{rem(50)}; // temp positioning, dependant on client sourced image
    background-size: cover;
    padding: rem(72) spacing(super) rem(96);
  }
}
