@mixin list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin thin-border {
  position: relative;

  &::after {
    border-bottom: 1px solid $darkest-gray;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    transform: scaleY(0.3);
    width: 100%;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

@mixin thin-border-top {
  position: relative;

  &::before {
    border-top: 1px solid $darkest-gray;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleY(0.3);
    width: 100%;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}
