@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.rail {
  background: $lightest-gray;
  color: $darkest-gray;
  overflow-x: hidden;
  position: relative;

  @include breakpoint-max(lg) {
    max-width: 0;
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
    width: 0;
    z-index: 999;

    .faq-content,
    .summary-refiners,
    .activity-detail-actions {
      display: none;

      .close {
        display: none;
      }
    }

    &.open {
      box-shadow: 1px 3px 8px 2px $boxshadow;
      padding: spacing(sm);

      .close {
        display: block;
        margin: 0 0 spacing(sm) auto;
      }
    }

    &.partial {
      &.open {
        max-width: 75%;
        width: 75%;
      }
    }

    &.full {
      &.open {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  @include breakpoint(lg) {
    max-width: 28%;
    min-width: 28%;
    padding: spacing(xl) spacing(md) rem(120);
  }

  .close {
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: none;
    padding: 0;
  }
}
