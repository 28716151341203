@import 'globals/scss/functions';
@import 'globals/scss/theme';

.error {
  color: $accme-red;
  font-size: rem(12);
  font-weight: 600;
  margin-bottom: rem(40);
  margin-top: rem(16);
}
