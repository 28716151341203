@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.button-row {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: rem(28);
  top: spacing(md);
  button {
    margin-left: spacing(md);
  }
}
