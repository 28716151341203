@import 'src/globals/scss/theme';

.container {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  position: relative;

  input {
    display: none;
    height: 0;
    width: 0;

    &:checked ~ .checkmark::after {
      height: calc(100% - 7px);
      opacity: 1;
      width: calc(100% - 7px);
    }
  }

  .checkmark {
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    position: relative;
    width: 24px;

    &::after {
      background-color: $accme-lime-green;
      border-radius: 50%;
      content: '';
      height: 0;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
    }
  }
}

.label {
  margin-left: 0.75em;
}
