@import 'globals/scss/variables';
@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/extensions';

:global {
  .MuiSnackbar {
    &-anchorOrigin {
      &TopCenter {
        top: 0 !important;
      }
      &BottomCenter {
        bottom: 0 !important;
      }
    }
  }
}

.toast-content {
  @include body-m;
  @include font-weight(semibold);
  align-items: center;
  display: flex;
  padding: rem(2) rem(28);
  width: 100%;

  &-centered {
    justify-content: center;
  }

  svg.icon {
    background: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    fill: $accme-lime-green;
    height: rem(40);
    margin-right: spacing(sm);
    padding: spacing(xs);
    width: rem(40);
  }

  .close {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding: spacing(xxs);

    svg {
      fill: $white;
      font-size: rem(20);
    }

    &:hover {
      background: rgba($color: #fff, $alpha: 0.2);
    }
  }

  > button {
    color: inherit;
    font-family: $font-family-sans-serif-body;
    font-size: rem(16);
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      background: none;
      color: inherit;
    }
  }
}
