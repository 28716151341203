@import 'mixins';
@import 'variables';

/* stylelint-disable */
html {
  border: 0;
  font-size: 100%;
  margin: 0;
  min-height: 100vh;
  min-width: 100%;
  padding: 0;
  & *,
  & ::before,
  & ::after {
    box-sizing: border-box;
  }
}

body {
  @include body-m;
  border: 0;
  margin: 0;
  min-height: 100vh;
  min-width: 100%;
  padding: 0;
  position: relative;
  width: 100%;
}

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}

h5 {
  @include heading-5;
}

h6 {
  @include heading-6;
}

// Note: This is to be used by add-activity form only.
.hidden {
  display: none !important;
}

#root,
.App {
  height: 100%;
  min-height: 100vh;
  position: relative;
}
