@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.container {
  margin: spacing(xl) 0 rem(48);
  position: relative;

  @include breakpoint(sm) {
    margin: spacing(xxxl) 0 rem(82);
  }

  @include breakpoint(md) {
    max-width: 66%;
  }
}
