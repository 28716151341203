@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/variables';

.account-settings {
  .heading {
    margin-bottom: spacing(md);
  }

  .button-row {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    right: rem(28);
    top: spacing(md);
    button {
      margin-left: spacing(md);
    }
  }

  .edit {
    button {
      @include breakpoint(sm) {
        max-width: rem(90);
      }
    }
  }

  .caption {
    @include caption-text;
  }

  ul.content {
    list-style-type: none;
    padding: 0;
  }

  .content,
  ul.content {
    margin: spacing(xxs) 0 spacing(md);
  }

  .not-provided {
    color: $medium-gray;
  }
}
