@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.footer {
  background-color: $accme-blue;
  bottom: 0;
  left: 0;
  min-height: 80px;
  padding: 0 spacing(sm);
  position: relative;
  width: 100%;

  @include breakpoint(sm) {
    padding: 0 rem(76);
  }

  &-top {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: spacing(xl) 0 rem(13);

    @include breakpoint-max(xs) {
      flex-flow: row wrap;

      :first-child {
        @include breakpoint-max(xs) {
          margin: 0 25% spacing(lg);
        }
      }
    }
  }

  &-bottom {
    display: block;
    padding: rem(27) 0;
    width: 100%;

    @include breakpoint(md) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &-left {
    @include font-size(14);
    color: $white;
    line-height: line-height(26, 14);
    margin-bottom: spacing(lg);
    text-align: center;

    @include breakpoint(xs) {
      margin-bottom: spacing(xs);
    }

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }

  &-right {
    text-align: center;

    a,
    a:visited {
      @include font-size(14);
      align-items: center;
      color: $white;
      display: inline-flex;
      line-height: line-height(26, 14);
      text-decoration: none;

      span {
        @include font-size(14);
        color: inherit;
        line-height: inherit;
      }

      &::after {
        content: '|';
        padding: 0 spacing(xs);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &:hover {
        color: $white;
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }
  }
}
