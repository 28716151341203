@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.contextual-dropdown {
  display: inline-flex;
  position: relative;

  &-selection {
    display: inline-flex;

    span {
      margin-right: 5px;
    }

    button {
      @include button-unstyled;
      align-items: center;
      display: inline-flex;
      justify-content: space-between;

      &:hover {
        color: inherit;
      }

      svg {
        font-size: rem(20);
      }
    }
  }

  &-menu {
    background-color: $white;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $darkest-gray;
    display: none;
    flex-flow: column nowrap;
    left: 50%;
    min-width: 100%;
    padding: spacing(sm);
    position: absolute;
    top: rem(34);
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 1;

    &.active {
      display: inline-flex;
    }

    ul {
      @include list-unstyled;
    }

    .menu-item {
      @include body-s;
      align-items: center;
      color: $darkest-gray;
      display: flex;
      flex-flow: row nowrap;
      padding: rem(12);
      width: 100%;

      &.selected {
        color: $accme-blue;
      }

      &:hover {
        background-color: $lightest-gray;
        color: $accme-blue;
        cursor: pointer;
      }

      .material-icons {
        margin-right: rem(5);
      }
    }
  }
}
