@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.summary-actions {
  &-options {
    @include body-s;
    @include thin-border;
    color: $darkest-gray;
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: spacing(md);
    position: relative;
    width: 100%;

    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    button {
      @include body-s;
      @include font-weight(semibold);
      color: inherit;
    }
  }

  &-info {
    align-items: center;
    display: flex;
    margin-bottom: spacing(xs);

    @include breakpoint(sm) {
      margin-bottom: 0;
    }

    button {
      @include body-s;
      @include font-weight(semibold);
      color: inherit;
    }

    > :first-child {
      &::after {
        content: '|';
        display: none;
        margin: 0 spacing(sm);

        @include breakpoint(sm) {
          display: inline-flex;
        }
      }
    }

    > :last-child {
      &::after {
        display: none;
      }
    }
  }

  &-utility {
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    button {
      @include font-weight(regular);
    }

    button.update-learners {
      background-color: $white;
      border: rem(1) solid $darkest-gray;
      border-radius: rem(24);
      font: inherit;
      margin: 0;
      padding: 0 spacing(sm);
      position: absolute;
      right: 0;
      top: 0;

      @include breakpoint(sm) {
        margin: 0 0 0 spacing(sm);
        position: relative;
        right: unset;
        top: unset;
      }

      &:hover {
        background-color: $white;
      }

      svg {
        font-size: inherit;
        margin-right: 0.5rem;
      }
    }
  }

  &-controls {
    @include body-s;
    align-items: center;
    color: $darkest-gray;
    justify-content: space-between;

    @include breakpoint(sm) {
      display: flex;
      margin: 0 spacing(md);

      .show-perpage {
        margin-left: -#{spacing(md)};
      }
    }

    button {
      @include body-s;
      @include font-weight(semibold);
      color: inherit;
    }

    > .select-control {
      display: inline-flex;
    }
  }

  &--checkbox {
    @extend %checkbox-styles;

    @include breakpoint(sm) {
      margin: 0;
    }

    span {
      @include body-s;

      &.checkmark {
        align-items: center;

        &::before {
          margin-top: 0;
        }
      }
    }
  }

  .show-perpage {
    display: none;

    @include breakpoint(sm) {
      display: block;
    }
  }

  .sort-controls {
    align-items: center;
    display: flex;

    .ascending {
      svg {
        box-shadow: 3px -1px 8px 2px $boxshadow; // box-shadow needs to remain in lower right quadrant while rotating
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    .descending {
      svg {
        box-shadow: -3px 1px 8px 2px $boxshadow; // box-shadow needs to remain in lower right quadrant while rotating
        transform: rotate(270deg);
        transform-origin: center;
      }
    }

    :global(.icon) {
      margin: 0 0 0 spacing(sm);

      svg {
        padding: spacing(xxs);
      }
    }
  }
}
