@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.container {
  @include body-s;
  margin: 0 auto;
  padding: 2rem 1.75rem;

  @include breakpoint(sm) {
    padding: 2rem 4.75rem;
  }

  h1 {
    @include heading-1;
  }

  h1,
  img {
    margin-bottom: 1.5rem;
  }

  img {
    max-width: 100%;
  }

  h2 {
    @include heading-3;
    color: $accme-turquoise;
    margin-bottom: 1rem;
  }

  a {
    color: $accme-blue;
  }
}
