@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.navigation {
  margin: spacing(md) rem(36) spacing(xxl) 0;

  .link {
    @include body-m;
    color: $accme-blue;
    text-align: left;
    text-decoration: none;
    &.disabled {
      color: $medium-gray;
    }
    &.selected {
      @include font-weight(semibold);
      color: $darkest-gray;
    }
  }

  ul {
    @include list-unstyled;

    li {
      @include thin-border;
      padding: rem(10) 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      a {
        @include body-m;

        &.selected {
          @include font-weight(semibold);
          color: $darkest-gray;
        }
      }
    }
  }
}

.cta {
  background-color: $light-warm-gray;
  border-radius: rem(24);
  margin-right: rem(36);
  padding: spacing(md) rem(34) spacing(xl);

  svg {
    background-color: $accme-lime-green;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $white;
    height: rem(40);
    padding: rem(4);
    width: rem(40);
  }

  p {
    @include body-s;
    margin: spacing(xs) 0 0;
  }

  a {
    @include hyperlink;
    color: $accme-blue;
    text-decoration: underline;
  }
}
