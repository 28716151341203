@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.form {
  align-items: center;
  display: flex;
  position: relative;

  .input-wrapper {
    width: 80%;
  }

  .box {
    position: relative;
    width: 98%; // Account for arrow btn offset.

    @include breakpoint(sm) {
      width: 100%;
    }

    .typeahead {
      width: 100%;
    }
  }

  &:focus-within {
    border-color: $accme-blue;
  }

  .submit {
    align-items: center;
    background-color: $accme-lime-green;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $white;
    display: flex;
    justify-content: center;
    min-width: unset;
    padding: 0;
    position: absolute;
    right: -#{rem(20)};
    top: rem(10);

    svg {
      height: rem(40);
      padding: rem(10);
      transform: rotate(180deg);
      width: rem(40);
    }

    &:hover {
      background-color: shade($accme-lime-green, 4%);

      &:active {
        box-shadow: none;
      }
    }
  }
}
