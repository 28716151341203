@import 'globals/scss/functions';
@import 'globals/scss/theme';

.recent {
  background-color: $white;
  box-shadow: 3px 3px 8px 2px $boxshadow;
  font-size: 0.8rem;
  left: rem(50);
  padding: 20px;
  position: absolute;
  top: 100%;
  width: calc(100% - #{rem(100)});
  z-index: 1;

  h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: rem(10);
  }

  ul {
    list-style: none;
    margin: 0 0 rem(10);
    padding: 0;

    li + li {
      margin-top: rem(10);
    }
  }

  .link {
    color: $dark-gray;
  }

  .clear {
    font-weight: 600;
  }
}
