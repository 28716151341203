@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.container {
  .heading {
    font-size: rem(24);
    font-weight: normal;
    padding-bottom: spacing(md);
    .caption {
      @include caption-text;
      font-size: rem(16);
      margin: spacing(xs) 0;
    }
  }
  h4 {
    margin-bottom: spacing(md);

    .caption {
      @include caption-text;
    }
  }
}
