@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/theme';

.container {
  $container-margin: rem(20px);

  background-color: $lightest-gray;
  border-radius: rem(24px);
  bottom: $container-margin;
  box-shadow: 1px 3px 8px 2px $boxshadow;
  display: grid;
  gap: rem(20px);
  max-width: calc(100vw - #{$container-margin} * 2);
  padding: rem(40px);
  position: fixed;
  right: $container-margin;
  width: rem(700px);
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .button:last-child {
    margin-left: rem(20px);
  }
}
