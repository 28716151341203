@import '../../../globals/scss/variables';
@import '../../../globals/scss/functions';
@import '../../../globals/scss/mixins';
@import '../../../globals/scss/extensions';

.profile-onboarding {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin-bottom: spacing(xl);
  padding: spacing(lg) rem(44) spacing(xl) rem(46);

  h5 {
    margin-bottom: spacing(md);
  }

  .caption {
    @include caption-text;
    margin: spacing(md) 0 rem(12);

    span {
      @include hyperlink;
      color: $accme-blue;
      text-decoration: underline;
    }
  }
}
