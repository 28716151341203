@import '../../../globals/scss/variables';
@import '../../../globals/scss/functions';
@import '../../../globals/scss/mixins';
@import '../../../globals/scss/extensions';

.masthead {
  align-items: center;
  border-bottom: 1px solid $lighter-gray;
  border-top: rem(38) solid $accme-blue;
  display: flex;
  justify-content: space-between;
  min-height: rem(152);
  padding: 0 spacing(super);

  @include breakpoint-max(xl) {
    padding: 0 rem(12);
  }

  .link,
  .link:visited {
    @include font-weight(semibold);
    color: $darkest-gray;
    font-size: rem(18);
    text-decoration: none;
  }

  .logo {
    max-width: rem(229);
  }

  nav {
    ul {
      @include list-unstyled;
      align-items: center;
      display: flex;
      justify-content: space-evenly;
    }

    a,
    button {
      @include font-weight(semibold);
      color: $darkest-gray;
      display: flex;
      font-size: rem(16);
      margin-right: spacing(lg);
      position: relative;

      @include breakpoint(lg) {
        font-size: rem(18);
        margin-right: rem(36);
      }
    }

    .button {
      padding: 0;

      &.profile {
        svg {
          height: rem(30);
          padding: rem(4.5);
          width: rem(30);
        }
      }

      &:hover {
        background-color: transparent;
        color: $accme-dark-blue;
      }
    }
  }
}

.logoImage {
  width: rem(357);
}
