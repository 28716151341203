@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.fieldset {
  background-color: $pale-blue;
  border: 0;
  border-radius: 20px;
  display: grid;
  gap: 30px;
  padding: 40px;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: rem(40);
  text-align: center;

  @include breakpoint(xl) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .link + .link {
    margin-left: rem(20);
  }
}
