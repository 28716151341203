@import 'globals/scss/theme';

.share {
  align-items: center;
  background-color: $white;
  bottom: 25px;
  box-shadow: 1px 3px 8px 2px $boxshadow;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  padding: 10px;
  position: absolute;

  .social {
    border-bottom: 1px solid $lighter-gray;
    display: flex;
    list-style: none;
    margin: 0 0 5px;
    padding: 0;

    li + li {
      margin-left: 10px;
    }
  }

  .copy {
    all: unset;
  }

  .copy {
    align-items: center;
    display: inline-flex;

    .check {
      color: $accme-lime-green;
    }

    svg {
      font-size: 1.25em;
      margin-right: 5px;
    }
  }

  button.copy {
    cursor: pointer;
  }
}
