@import 'globals/scss/mixins';
@import 'globals/scss/extensions';

.button {
  margin-left: auto;
  margin-right: auto;
}

.content {
  @include body-m;
  height: 100%;
  width: 100%;
}

.loading {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.container {
  margin: 0 auto;
  padding: 2rem 1.75rem;

  @include breakpoint(sm) {
    padding: 2rem 4.75rem;
  }

  h2 {
    @include heading-3;
    color: $darkest-gray;
    margin-bottom: 1rem;
  }
}
