@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.container {
  background-color: $accme-purple;
  padding: spacing(md) spacing(sm) rem(36);
  position: relative;

  @include breakpoint(md) {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: rem(18) spacing(super) rem(18);
  }

  .message {
    @include body-m;
    @include font-weight(semibold);

    color: $white;
    display: flex;
    justify-content: center;

    + .button {
      margin: spacing(md) 0 0;

      @include breakpoint(xs) {
        margin: spacing(md) auto 0;
        max-width: rem(480);
        min-width: 0;
      }

      @include breakpoint(sm) {
        max-width: rem(320);
      }

      @include breakpoint(md) {
        margin: 0 0 0 spacing(lg);
      }
    }
    svg {
      margin-right: spacing(xs);
    }
  }
}
