@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.container {
  background: bottom center; // temp positioning, dependant on client sourced image
  background-size: cover;
  padding: rem(40) spacing(sm);

  @include breakpoint(md) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: rem(64) spacing(super) rem(66);
  }

  h3 {
    margin-bottom: spacing(xl);

    @include breakpoint(md) {
      margin-bottom: 0;
      max-width: 66%;
    }
  }
}
