@mixin breakpoint($size) {
  @if map-has-key($grid-breakpoints, $size) {
    @media screen and (min-width: map-get($grid-breakpoints, $size)) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}

@mixin breakpoint-max($size) {
  @if map-has-key($grid-breakpoints, $size) {
    @media screen and (max-width: #{map-get($grid-breakpoints, $size) - 1}) {
      @content;
    }
  } @else {
    @media (max-width: #{$size - 1}) {
      @content;
    }
  }
}
