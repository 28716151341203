@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/variables';

.container {
  background-color: $palest-blue;
  display: grid;
  padding: 2rem 1.75rem;
  width: 100%;

  &.has-sidebar {
    @include breakpoint(md) {
      grid-template-columns: 3fr 350px;
    }
  }

  h1 {
    @include heading-3;
  }

  ul {
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;

    li {
      display: flex;
      font-size: 1.25rem;
      margin: 1.5rem 0;

      .icon {
        background-color: $accme-lime-green;
        border-radius: 50%;
        box-shadow: 1px 3px 8px 2px $boxshadow;
        color: $white;
        font-size: 0.6em;
        height: 3.5em;
        margin-right: 2rem;
        margin-top: -5px;
        padding: 0.8em;
        width: 3.5em;
      }
    }
  }
}

.aside {
  background-color: $lightest-gray;
  padding: 2rem;

  @include breakpoint(md) {
    padding: 2rem 4.75rem 2rem 2rem;
  }

  h2 {
    @include heading-5;
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @include body-m;

      + li {
        border-top: 1px solid $lighter-gray;
        margin-top: 1rem;
        padding-top: 1rem;
      }
    }
  }

  p {
    margin: 0;
  }

  a {
    color: $accme-blue;
    text-decoration: none;
  }
}
