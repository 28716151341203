@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.container {
  position: relative;
  .eyebrow {
    @include eyebrow-l;
    color: $accme-olive;
    margin-bottom: spacing(sm);
    text-transform: uppercase;
  }

  ul {
    @include list-unstyled;
    @include breakpoint(md) {
      display: flex;
      justify-content: space-between;
    }

    li {
      margin: 0 0 spacing(md);

      @include breakpoint(md) {
        margin: 0;
        width: calc(25% - #{rem(56)});
      }

      .link {
        @include body-s;
        @include font-weight(bold);
        letter-spacing: rem(0.44);
        margin-bottom: spacing(sm);

        svg {
          margin: 0 0 spacing(xxs) spacing(xxs);
        }
      }

      .content {
        align-items: flex-start;
        display: flex;

        p {
          @include caption-text;
          margin: 0 0 0 spacing(xs);
          max-width: 75%;
        }
      }
    }
  }

  .icon {
    align-items: center;
    background: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $accme-lime-green;
    display: flex;
    height: rem(30);
    justify-content: center;
    margin-right: rem(8);
    margin-top: rem(4);
    padding: rem(6);
    width: rem(30);

    svg {
      padding: spacing(xxs);
    }
  }
}
