@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.learner-results-card {
  background-color: $pale-blue;
  border-radius: rem(24);
  display: grid;
  grid-template-columns: 1fr max-content;
  margin: spacing(sm) 0;
  padding: spacing(md) spacing(md);
  position: relative;

  @include breakpoint(sm) {
    margin-bottom: spacing(md);
    padding-bottom: spacing(md);

    &:hover {
      .activity-info,
      .meta {
        &:last-child {
          margin-bottom: spacing(sm);
        }
      }
    }
  }

  &-title {
    @include body-m;
    @include font-weight(semibold);
    color: $accme-blue;
    margin-bottom: spacing(sm);

    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: spacing(xs);
    }
  }

  &-icons {
    color: $accme-lime-green;
  }

  &-cta {
    @include caption-text;
    color: $darkest-gray;
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(md);
    position: relative;

    @include breakpoint(sm) {
      justify-content: flex-end;
      margin-bottom: 0;
      order: 1;
    }

    a.button {
      background-color: $white;
      border: rem(1) solid $darkest-gray;
      border-radius: rem(24);
      margin-left: spacing(md);
      padding: 0 spacing(sm);

      &:hover {
        background-color: $white;
      }
    }
  }

  &-info-container {
    @include caption-text;
    display: grid;
    margin: spacing(sm) 0 0;

    @include breakpoint(sm) {
      display: flex;
      justify-content: space-between;
    }

    strong {
      font-weight: 600;
    }
  }

  .org-info,
  .activity-info {
    display: flex;
    margin-bottom: spacing(xxs);

    span + span {
      &::before {
        content: '|';
        padding: 0 spacing(xxs);
      }
    }
  }

  .credits-info {
    display: inline-flex;
    flex-wrap: wrap;

    > span,
    span + span {
      padding-right: spacing(md);
      strong {
        margin-left: spacing(xxs);
      }
    }
  }

  .meta {
    margin-bottom: spacing(sm);

    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }

    &-type {
      @include font-weight(semibold);
    }

    &-id,
    &-date,
    &-other {
      @include breakpoint(sm) {
        &::after {
          content: '|';
          padding: 0 spacing(xxs);
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .records {
    display: inline-flex;

    .record-status {
      display: inline-flex;
      margin-right: spacing(xs);

      span {
        @include font-weight(semibold);
        margin-left: spacing(xxs);
      }
    }
  }

  &-utility {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .moc-credit {
    display: inline-flex;

    abbr {
      text-decoration: none;
    }

    ul {
      display: inline-flex;
      list-style: none;
      margin: 0 0 0 spacing(xxs);
      padding: 0;

      li {
        &:not(:last-child) {
          margin-right: spacing(xxs);
          &::after {
            content: ',';
          }
        }
      }
    }
  }
}
