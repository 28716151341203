@import '../../globals/scss/variables';
@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/extensions';

.section {
  background-color: $palest-blue;
  overflow: hidden;
  padding: spacing(md) spacing(sm) spacing(xl);
  position: relative;
  width: 100%;

  @include breakpoint(sm) {
    padding: spacing(xl) rem(28) spacing(xl);
  }

  @include breakpoint(md) {
    padding: spacing(xl) spacing(super) spacing(xl) rem(52);
  }

  @include breakpoint(lg) {
    padding: spacing(xl) spacing(super) rem(120) rem(72);
  }

  .page-navigation {
    margin-bottom: spacing(md);

    @include breakpoint(sm) {
      margin-bottom: spacing(xl);
    }
  }

  :global {
    .btn-actions,
    .btn-addfilter {
      display: none;

      @include breakpoint-max(lg) {
        display: flex;
      }
    }
  }
}
