@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.multi-autocomplete {
  .label {
    color: $dark-gray;
    font-size: rem(12);
    font-weight: 600;
    letter-spacing: rem(1.5);
    line-height: 1.66667;
    margin-bottom: spacing(xs);
    text-transform: uppercase;

    .required {
      color: $accme-red;
      margin-left: 5px;
    }
  }
  .selected-summary {
    margin-top: spacing(md);
    &-item {
      align-items: center;
      border: rem(0.3) solid $medium-gray;
      border-radius: rem(12);
      box-shadow: inset 0 1px 3px 0 #00000014;
      color: $darkest-gray;
      display: flex;
      justify-content: space-between;
      margin-bottom: spacing(xs);
      padding: rem(14) spacing(sm);
      &:last-child {
        margin-bottom: 0;
      }
      button {
        align-items: center;
        display: flex;
        svg {
          color: $darkest-gray;
        }
      }
    }
  }
  .option-content {
    &-label {
      @include body-m;
    }
    &-caption {
      @include caption-text;
    }
  }
}
