@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.card {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin: spacing(sm) 0;
  padding: spacing(md);
  position: relative;
}
