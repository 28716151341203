@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/variables';

.container {
  background-color: $palest-blue;
  display: grid;
  gap: 2rem;
  padding: 2rem 1.75rem;

  @include breakpoint(md) {
    gap: 4rem;
    grid-template-columns: 1fr 1fr;
    padding: 4.75rem;
  }

  h1 {
    @include heading-3;
  }

  ul {
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;

    li {
      display: flex;
      font-size: 1.25rem;
      margin: 1.5rem 0;

      .icon {
        background-color: $accme-lime-green;
        border-radius: 50%;
        box-shadow: 1px 3px 8px 2px $boxshadow;
        color: $white;
        font-size: 0.6em;
        height: 3.5em;
        margin-right: 2rem;
        margin-top: -5px;
        padding: 0.8em;
        width: 3.5em;
      }
    }
  }
}
