@import 'typography';

@mixin heading-1 {
  @include header-font-family;
  @include font-size(32);
  @include font-weight(regular);
  line-height: line-height(40, 32);
  margin: 0;
  @include breakpoint(sm) {
    @include font-size(44);
    line-height: line-height(56, 44);
  }
}

@mixin heading-2 {
  @include header-font-family;
  @include font-size(30);
  @include font-weight(regular);
  line-height: line-height(38, 30);
  margin: 0;
  @include breakpoint(sm) {
    @include font-size(36);
    line-height: line-height(48, 36);
  }
}

@mixin heading-3 {
  @include header-font-family;
  @include font-size(26);
  @include font-weight(regular);
  line-height: line-height(32, 26);
  margin: 0;
  @include breakpoint(sm) {
    @include font-size(30);
    line-height: line-height(42, 30);
  }
}

@mixin heading-4 {
  @include header-font-family;
  @include font-size(22);
  @include font-weight(regular);
  line-height: line-height(30, 22);
  margin: 0;
  @include breakpoint(sm) {
    @include font-size(24);
    line-height: line-height(36, 24);
  }
}

@mixin heading-5 {
  @include header-font-family;
  @include font-size(18);
  @include font-weight(regular);
  line-height: line-height(24, 18);
  margin: 0;
  @include breakpoint(sm) {
    @include font-size(20);
    line-height: line-height(26, 20);
  }
}

@mixin heading-6 {
  @include header-font-family;
  @include font-size(16);
  @include font-weight(regular);
  line-height: line-height(24, 16);
  margin: 0;
}

@mixin subheading-1 {
  @include body-font-family;
  @include font-size(16);
  @include font-weight(regular);
  letter-spacing: rem(0.15);
  line-height: line-height(24, 16);
}

@mixin subheading-2 {
  @include body-font-family;
  @include font-size(14);
  @include font-weight(medium);
  letter-spacing: rem(0.1);
  line-height: line-height(24, 14);
}

@mixin eyebrow-l {
  @include body-font-family;
  @include font-size(14);
  @include font-weight(bold);
  letter-spacing: rem(2.92);
  line-height: line-height(22, 14);
  text-transform: uppercase;
}

@mixin eyebrow {
  @include font-weight(semibold);
  @include font-size(12);
  letter-spacing: rem(1.5);
  line-height: line-height(20, 12);
  text-transform: uppercase;
}

@mixin body-l {
  @include body-font-family;
  @include font-size(18);
  @include font-weight(regular);
  line-height: line-height(30, 18);
}

@mixin body-m {
  @include body-font-family;
  @include font-size(16);
  @include font-weight(regular);
  line-height: line-height(26, 16);
}

@mixin body-s {
  @include body-font-family;
  @include font-size(14);
  @include font-weight(regular);
  line-height: line-height(22, 14);
}

@mixin button-text {
  @include body-font-family;
  @include font-size(16);
  @include font-weight(bold);
  letter-spacing: rem(0.5);
  line-height: line-height(16, 16);
}

@mixin caption-text {
  @include body-font-family;
  @include font-size(12);
  @include font-weight(regular);
  letter-spacing: rem(0.4);
  line-height: line-height(20, 12);
}

@mixin hyperlink {
  @include body-font-family;
  @include font-size(14);
  @include font-weight(regular);
  letter-spacing: rem(0.44);
  line-height: line-height(20, 14);
}
