@import '../globals/scss/functions';
@import '../globals/scss/mixins';
@import '../globals/scss/variables';

.main {
  display: flex;
  min-height: calc(100vh + #{rem(300)}); //makes it so container fills the page
  position: relative;

  @include breakpoint(sm) {
    min-height: calc(100vh - #{rem(281)});
  }
}
