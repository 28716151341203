@import 'globals/scss/theme';

.form {
  display: grid;
  gap: 30px;

  .fieldset {
    background-color: $pale-blue;
    border: 0;
    border-radius: 20px;
    display: grid;
    gap: 30px;
    padding: 40px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
