@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.detail-title {
  margin: spacing(lg) 0 spacing(sm) 0;
}
.detail-section {
  background-color: $pale-blue;
  border-radius: rem(24);
  color: $darkest-gray;
  margin: spacing(md) 0;
  padding: spacing(md) spacing(xl);
  .detail-snippet {
    margin-top: spacing(sm);
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .caption-text {
      font-size: rem(12);
    }
    .detail-content {
      font-size: rem(16);
      margin: 0;
    }
  }
  .activity-url,
  .activity-url:visited {
    @include body-m;
    color: $accme-blue;
    text-align: left;
    text-decoration: none;

    &:hover {
      color: $accme-dark-blue;
      text-decoration: underline;
    }

    nav & {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.aside {
  max-width: rem(250);
  padding-top: rem(65);
  &-divider {
    margin: spacing(md) 0;
  }
  .cta-share {
    position: relative;
  }
  .cta-image {
    background-color: $white;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: rem(4);
    min-height: rem(150);
  }
  .button {
    margin-top: spacing(lg);
    max-width: rem(196);
    > span {
      color: $accme-purple;
    }
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  ul {
    display: inline-flex;
  }
  li {
    margin-left: spacing(xxs);
  }
  li::after {
    content: ',';
  }
  li:last-child::after {
    content: '';
  }
}
