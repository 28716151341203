@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.section {
  background-color: $palest-blue;
  color: $darkest-gray;
  gap: 2rem;
  padding: 2rem;

  @include breakpoint(md) {
    padding: 2rem 4.75rem;
  }

  .box {
    background-color: $pale-blue;
    border: 0;
    border-radius: 20px;
    gap: 30px;
    margin-bottom: 2rem;
    padding: 40px;

    h1 {
      @include heading-3;
      margin-bottom: 2rem;
    }

    .eyebrow {
      @include eyebrow-l;
      color: $accme-olive;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;

    .button + .button {
      margin-left: 1rem;
    }
  }
}
