@import 'theme';

// Typography Tokens
$font-family-sans-serif-header: 'Work Sans', Geneva, Helvetica, Arial, sans-serif;
$font-family-sans-serif-body: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
$font-display: swap;

$base-font-size: 16px; // Default, Use with em() and rem() functions

$font-weights: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extra-bold: 800,
);

$font-size-map: (
  96: rem(96),
  60: rem(60),
  48: rem(48),
  44: rem(44),
  36: rem(36),
  34: rem(34),
  32: rem(32),
  30: rem(30),
  26: rem(26),
  24: rem(24),
  22: rem(22),
  20: rem(20),
  18: rem(18),
  16: rem(16),
  14: rem(14),
  12: rem(12),
  10: rem(10),
);

// Spacing
$spacing: (
  xxs: rem(4),
  xs: rem(8),
  sm: rem(16),
  md: rem(24),
  lg: rem(32),
  xl: rem(40),
  xxl: rem(48),
  xxxl: rem(56),
  super: rem(76),
  super-l: rem(96),
  grid-gutter-mobile: rem(16),
  grid-gutter-tablet: rem(8),
  grid-gutter-half: rem(12),
  grid-gutter: rem(24),
);

// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29'));

// Breakpoints
$grid-breakpoints: (
  xxs: 321px,
  xs: 415px,
  sm: 768px,
  md: 992px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
);
