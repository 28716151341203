@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.subheading {
  @include heading-4;
  margin-bottom: 2rem;
}

.form {
  display: grid;
  gap: 30px;
}

.fieldset {
  background-color: $pale-blue;
  border: 0;
  border-radius: 20px;
  color: $dark-gray;
  display: grid;
  gap: 20px;
  padding: 20px;

  @include breakpoint(md) {
    padding: 40px;
  }
}

.row {
  .grid {
    display: grid;
    gap: 20px;
  }

  &.name {
    .grid {
      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      }
    }
  }

  &.details {
    .grid {
      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr) minmax(0, 2fr);
      }
    }
  }

  &.physician {
    .grid {
      gap: 5px;

      .radio + .radio {
        margin-left: 20px;
      }
    }
  }

  &.license {
    .grid {
      grid-template-columns: minmax(0, 1fr);

      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr);
      }
    }
  }

  &.npi {
    .grid {
      align-items: center;

      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr) 1fr;
      }
    }

    a {
      color: $accme-blue;
      display: inline-block;
      margin-top: 1.5em;
    }
  }

  &.state {
    .grid {
      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr) 1fr;
      }
    }
  }

  &.board {
    display: grid;
    gap: 20px;

    .grid {
      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1.5fr) minmax(0, 1fr);
      }
    }
  }

  &.alternate {
    .grid {
      @include breakpoint(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 1fr;
      }
    }
  }
}

.divider {
  align-items: center;
  display: flex;
  text-align: center;

  &::before,
  &::after {
    border-bottom: 1px solid $light-gray;
    content: '';
    flex: 1;
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
}

.message {
  align-items: center;
  background-color: $accme-peach;
  color: $darkest-gray;
  display: flex;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;

  svg {
    height: 1rem;
    margin-right: 0.5rem;
    width: 1rem;
  }
}

.contact-message {
  color: $accme-red;
  font-weight: 600;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
