@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.section {
  background-color: $palest-blue;
  padding: 2rem;

  @include breakpoint(md) {
    padding: 2rem 4.75rem;
  }

  .eyebrow {
    @include eyebrow-l;
    color: $accme-olive;
  }
}
