//-------------------------
// 🌍 Global
//-------------------------

@import './globals/scss/reset';
@import './globals/scss/fonts';

body {
  -webkit-font-smoothing: antialiased;
}
