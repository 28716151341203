@mixin button-unstyled {
  background: none;
  border: 0;
  margin: 0;
  min-width: unset;
  outline: none;
  padding: 0;
}

@mixin button-flex {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin button() {
  align-items: center;
  background: none;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  height: 36px;
  opacity: 1;
  padding: 10px 20px;
  transition: color 250ms ease, background 250ms ease;

  &[data-btn-ripple] {
    overflow: hidden;
    position: relative;

    .ripple--container {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      span {
        animation: ripple 1s;
        background-color: $white;
        border-radius: 100%;
        opacity: 0.75;
        position: absolute;
        transform: scale(0);
      }
    }
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
}

// Button Variants
/*
 * @param $bg-color Bcakground color of the variant
 */
@mixin button-variant(
  $bg-color: $white,
  $bg-hover-color: $primary,
  $font-color: $primary,
  $border-color: transparent,
  $focus-border-color: transparent
) {
  background: $bg-color;
  background-clip: padding-box;
  border: 1px solid $border-color;
  color: $font-color;
  overflow: hidden;

  &:hover {
    background-color: $bg-hover-color;
    border: 1px solid transparent;
  }

  &:focus {
    outline: 2px solid $focus-border-color;
  }

  &[disabled] {
    background-color: $white;
    border-color: transparent;
    color: rgba($black, 0.38);
    pointer-events: none;
  }
}
