@import '../../../globals/scss/variables';
@import '../../../globals/scss/functions';
@import '../../../globals/scss/mixins';
@import '../../../globals/scss/extensions';

.masthead {
  align-items: center;
  border-bottom: 1px solid $lighter-gray;
  display: flex;
  min-height: rem(73);
  padding: 0 spacing(sm);

  .hamburger {
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: unset;
    padding: 0;
  }

  .logo {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: rem(9) 0 0 spacing(md);
    width: 100%;

    svg {
      max-width: rem(258);
    }
  }
}

.menu-container {
  height: 0;
  overflow: hidden;
  transform-origin: top;
  transition: height 0.3s ease-out;

  &.open {
    height: calc(100vh - #{rem(73)});
    overflow: auto;
  }

  .menu {
    @include list-unstyled;
    position: relative;

    .menu-btn {
      @include thin-border;
      @include font-weight(semibold);
      align-items: center;
      color: $darkest-gray;
      display: flex;
      font-family: $font-family-sans-serif-body;
      font-size: rem(16);
      justify-content: space-between;
      line-height: line-height(22, 16);
      padding: rem(15) spacing(sm);
      text-transform: none;
      width: 100%;

      span {
        align-items: center;
        display: inline-flex;
      }

      &:last-child {
        &::after {
          display: initial;
        }
      }
    }
  }
}

.logoImage {
  width: rem(258);
}
