@import '../../globals/scss/functions';
@import '../../globals/scss/mixins';
@import '../../globals/scss/variables';

.element,
.element:visited {
  @include body-m;
  color: $accme-blue;
  text-align: left;
  text-decoration: none;

  &:hover {
    color: $accme-dark-blue;
    text-decoration: underline;
  }

  nav & {
    &:hover {
      text-decoration: none;
    }
  }
}

.element {
  @include button-unstyled;

  &.primary {
    @include button-text;
    align-items: center;
    background: $accme-purple;
    border: 0;
    border-radius: rem(26.5);
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: rem(180);
    opacity: 1;
    padding: spacing(sm);

    &:hover {
      background-color: $accme-dark-purple;
      color: $white;
      text-decoration: none;
    }

    + .tertiary {
      margin-top: spacing(md);
    }

    &:disabled {
      background-color: $lighter-gray;
      color: $medium-gray;
      cursor: unset;
    }
  }

  &.secondary {
    @include button-text;
    align-items: center;
    background: $white;
    border: rem(2) solid $accme-purple;
    border-radius: rem(26.5);
    box-sizing: border-box;
    color: $accme-purple;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: rem(90);
    opacity: 1;
    padding: rem(14);

    &:hover {
      background: $white;
      border-color: $accme-dark-purple;
      color: $accme-dark-purple;
    }

    &:disabled {
      border-color: $lighter-gray;
      color: $lighter-gray;
      cursor: unset;

      &:hover {
        border-color: $lighter-gray;
        color: $lighter-gray;
      }
    }
  }

  &.tertiary {
    @include button-text;
    align-items: center;
    color: $accme-blue;
    display: inline-flex;
    letter-spacing: rem(0.5);
    padding: 0;
    text-transform: capitalize;

    &--small {
      @include body-s;
      letter-spacing: rem(0.44);
    }

    .custom-icon {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0 spacing(xs);
      transform: rotate(180deg);

      &-back {
        margin: 0 spacing(sm) 0 0;
        transform: rotate(0deg);
      }
    }

    &:hover {
      color: $accme-dark-blue;
      cursor: pointer;
    }
  }

  &.link,
  &.link--hyperlink {
    color: $accme-blue;
    font-family: inherit;
    font-size: 1em;
    height: auto;
    letter-spacing: 0;
    line-height: 1.625;
    min-width: 0;
    padding: 0;
    text-align: left;
    vertical-align: top;
    width: auto;

    &:hover {
      color: $accme-dark-blue;
      cursor: pointer;
    }
  }

  &.icon {
    @include body-m;
    align-items: center;
    color: $darkest-gray;
    display: flex;
    margin-top: rem(6);

    svg {
      background: $white;
      border: 0;
      border-radius: 50%;
      box-shadow: 3px 3px 8px 2px $boxshadow;
      fill: $accme-lime-green;
      margin-right: spacing(xs);
    }

    &:hover:active {
      svg {
        box-shadow: none;
      }
    }

    &.ascending {
      svg {
        box-shadow: 3px -1px 8px 2px $boxshadow; // box-shadow needs to remain in lower right quadrant while rotating
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    &.descending {
      svg {
        box-shadow: -3px 1px 8px 2px $boxshadow; // box-shadow needs to remain in lower right quadrant while rotating
        transform: rotate(270deg);
        transform-origin: center;
      }
    }

    &:hover {
      color: $accme-blue;
    }
  }

  &.label {
    align-items: center;
    color: inherit;
    display: flex;
    font: inherit;
    padding: 0;
    text-decoration: none;

    svg {
      font-size: inherit;
      margin-right: spacing(xs);
    }

    &:hover {
      color: $accme-blue;
      text-decoration: none;
      svg {
        color: initial;
      }
    }
  }

  &.btn {
    &-faq,
    &-actions {
      align-items: center;
      background: $accme-peach;
      border: 0;
      border-radius: rem(24);
      box-sizing: border-box;
      color: $darkest-gray;
      cursor: pointer;
      display: flex;
      font-family: $font-family-sans-serif-body;
      font-size: rem(12);
      letter-spacing: rem(0.25);
      opacity: 1;
      padding: spacing(xxs) spacing(sm);
    }

    &-addfilter {
      @include body-s;
      align-items: center;
      background: $white;
      border: rem(0.5) solid $warm-gray;
      border-radius: rem(24);
      box-sizing: border-box;
      color: $darkest-gray;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: spacing(md) 0 spacing(sm);
      opacity: 1;
      padding: spacing(xxs) spacing(sm);

      @include breakpoint-max(xs) {
        width: 100%;
      }

      svg {
        margin-right: spacing(xs);
      }
    }
  }

  &.close {
    color: $darkest-gray;
  }

  &.remove {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-left: spacing(xxs);
    padding: spacing(xxs);

    &:hover {
      background-color: transparentize($black, 0.04);
    }
  }

  &.pill {
    align-items: center;
    background-color: $white;
    border: solid rem(0.5) $lighter-gray;
    border-radius: rem(24);
    color: $darkest-gray;
    display: flex;
    flex-wrap: nowrap;
    font-size: rem(12);
    justify-content: center;
    margin: 0 spacing(xs) spacing(xs) 0;
    padding: spacing(xxs) rem(12);
    white-space: nowrap;

    svg {
      font-size: rem(14);
      margin-left: rem(12);
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: $medium-gray;
    cursor: unset;
    pointer-events: none;

    svg {
      color: inherit;
    }
  }
}
