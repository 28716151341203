@import '../functions/typography';
@import '../variables';

@mixin header-font-family {
  font-family: $font-family-sans-serif-header;
}

@mixin body-font-family {
  font-family: $font-family-sans-serif-body;
}

@mixin font-weight($weight) {
  @if map-has-key($font-weights, $weight) {
    font-weight: map-get($font-weights, $weight);
  } @else {
    @warn '`#{$weight}` is not an option for font-weight! Valid options are: thin, extra-light, light, regular, medium, semibold, bold, extra-bold';
  }
}

@mixin font-size($size) {
  $unitless-size: strip-units($size);

  @if map-has-key($font-size-map, $unitless-size) {
    font-size: map-get($font-size-map, $unitless-size);
  } @else {
    @warn 'This is not a valid step on the type scale! Valid sizes are 10, 12, 14, 16, 20, 24, 34, 48, 50, and 96';
  }
}

// Only applied to bold weight text
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
