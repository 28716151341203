@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.boards-repeater {
  display: block;

  .input-group {
    @include input-styles(
      '%input-styles-nowidth',
      '%input-styles--focus',
      '%input-styles--error',
      '%input-styles--disabled'
    );

    &--inline {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: spacing(sm);

      input {
        &:first-child {
          width: 65%;
        }

        &:last-child {
          width: calc(35% - #{rem(14)});
        }
      }
    }
  }

  .add {
    align-items: center;
    display: flex;
    margin-top: spacing(md);

    button {
      background: $white;
      color: $accme-lime-green;
    }

    span.text {
      margin-left: spacing(sm);
    }
  }

  .remove {
    align-items: flex-start;
    display: flex;
    margin-top: spacing(xs);
  }

  .caption {
    @include caption-text;
    margin-top: spacing(md);
  }

  .button-row,
  .space-between-button-row {
    display: flex;
    flex-direction: column;
    margin-top: spacing(md);
    @include breakpoint(sm) {
      flex-direction: row;
    }
    .wizard-button {
      margin-top: spacing(xs);
    }
  }

  .button-row {
    @include breakpoint(sm) {
      flex-direction: row;
    }
  }
}
