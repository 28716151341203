@import 'src/globals/scss/theme';
@import 'src/globals/scss/mixins/typography';
@import 'src/globals/scss/extensions';

.card {
  background-color: $pale-blue;
  border-radius: rem(24);

  .heading {
    @include body-font-family;
    font-size: rem(20);
    font-weight: 600;
  }

  .divider {
    border-bottom: rem(1) solid $medium-gray;
    margin: spacing(xs) 0;
  }

  .checkbox {
    @extend %checkbox-styles;
  }

  .pipe-separated {
    @extend %pipe-separated;
  }

  .indent {
    margin-left: spacing(sm);
  }
}
