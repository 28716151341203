@import '../../../globals/scss/variables';
@import '../../../globals/scss/functions';
@import '../../../globals/scss/mixins';
@import '../../../globals/scss/extensions';

.recent-completed-cme {
  h4 {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
