@mixin input-styles($styles, $focus_styles, $error_styles, $disabled_styles) {
  $types: 'email', 'number', 'password', 'search', 'tel', 'text', 'url', 'color', 'date', 'datetime', 'datetime-local',
    'month', 'time', 'week';

  @each $type in $types {
    input[type='#{$type}'] {
      &:not(.MuiInputBase-input) {
        @extend #{$styles};
      }

      &:focus {
        &:not(.MuiInputBase-input) {
          @extend #{$focus_styles};
        }
      }
    }
    &.has_error {
      input[type='#{$type}'] {
        &:not(.MuiInputBase-input) {
          @extend #{$error_styles};
        }

        &:focus {
          &:not(.MuiInputBase-input) {
            @extend #{$focus_styles};
          }
        }
      }
    }
    &.disabled {
      input[type='#{$type}'] {
        &:not(.MuiInputBase-input) {
          @extend #{$disabled_styles};
        }
      }
    }
  }
  select,
  textarea {
    &:not(.MuiInputBase-input) {
      @extend #{$styles};
      padding: 1rem;
    }

    &:focus {
      &:not(.MuiInputBase-input) {
        @extend #{$focus_styles};
      }
    }
  }
  &.has_error {
    select,
    textarea {
      &:not(.MuiInputBase-input) {
        @extend #{$error_styles};
      }

      &:focus {
        &:not(.MuiInputBase-input) {
          @extend #{$focus_styles};
        }
      }
    }
  }
}
