@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.container {
  position: relative;
  @include breakpoint(md) {
    max-width: 66%;
    h2 {
      align-items: center;
      display: flex;
    }
  }

  .welcome-prompt {
    margin-right: rem(12); // white space between prompt and user name
  }
}
